import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/Section";
import ConfusedGif from "../images/gifs/confused.gif";

const GifWrapper = styled.div`
  flex: 1 0 auto;
`;

const NotFoundPage = () => (
  <Layout darkbg>
    <SEO title="404: Not found" />
    <Section title="Ouch, not found!" description="We can't find what you're looking for... ¯\_(ツ)_/¯" centered darkbg>
      <GifWrapper>
        <img src={ConfusedGif} alt="Confused Travolta" />
      </GifWrapper>
    </Section>
  </Layout>
);

export default NotFoundPage;
